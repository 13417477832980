import {
  call,
  delay,
  put,
  put as putInternal,
  race,
  select,
  take,
} from "redux-saga/effects";
import {
  ApiError,
  BadRequestError,
  Error400Model,
  ErrorNoContent,
  NotFoundRequestError,
  UnauthorizedError,
} from "./BaseModels";
import { authSlice, selectToken } from "./auth/AuthSlice";
import { TokenModel } from "./auth/AuthModel";
import { BaseAppSettings } from "./BaseAppSettings";

declare const appSettings: BaseAppSettings;

let isExpired = true;

export function* httpGet(resourceUrl: string): unknown {
  const result = yield fetchBase("GET", resourceUrl);
  return result;
}

export function* httpGetFile(resourceUrl: string): unknown {
  const result = yield fetchFileBase("GET", resourceUrl);
  return result;
}

export function* httpPost<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("POST", resourceUrl, data, undefined);
  return result;
}

export function* httpPut<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("PUT", resourceUrl, data);
  return result;
}

export function* httpDelete(resourceUrl: string): unknown {
  const result = yield fetchBase("DELETE", resourceUrl);
  return result;
}

export function* httpPostForm(resourceUrl: string, form: FormData): unknown {
  const result = yield fetchFormBase("POST", resourceUrl, form);
  return result;
}

export function* httpPutForm(resourceUrl: string, form: FormData): unknown {
  const result = yield fetchFormBase("PUT", resourceUrl, form);
  return result;
}

function* getToken() {
  yield putInternal(authSlice.actions.getToken());

  const token: TokenModel = yield select(selectToken);

  if (token) {
    return token.accessToken;
  }

  while (true) {
    const { action, cancel } = yield race({
      action: take(authSlice.actions.tokenReceived),
      cancel: take(authSlice.actions.tokenRejected),
    });

    if (action) {
      return action.payload.accessToken;
    } else if (cancel) {
      throw new UnauthorizedError("Authentication rejected");
    }
  }
}

function* getOrg() {
  const token: string = yield call(getToken);
  const claims = JSON.parse(atob(token.split(".")[1]));
  return claims.Organization;
}

function* getLang() {
  const token: string = yield call(getToken);
  const claims = JSON.parse(atob(token.split(".")[1]));
  return claims.Lang;
}

function* fetchBase(
  method: string,
  resourceUrl: string,
  data: unknown = null,
  retry = false
): unknown {
  const token = yield call(getToken);
  const orgId = yield call(getOrg);
  const lng = yield call(getLang);
  const result = yield fetch(`${appSettings.apiBaseUrl}${resourceUrl}`, {
    method: method,
    headers: {
      Authorization: "Bearer " + token ?? "",
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": `application/vnd.domino.v1+json`,
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-organization": orgId,
      "x-domino-lang": lng,
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  return yield handleResponse(result, method, resourceUrl, retry, data);
}

function* handleResponse(
  response: Response,
  method: string,
  resourceUrl: string,
  retry?: boolean,
  data: unknown = null
): unknown {
  if (response?.status === 401) {
    if (!retry) {
      isExpired = !isExpired;
      yield put(authSlice.actions.refreshToken());
      response = yield fetchBase(method, resourceUrl, data, true);
    } else {
      throw new UnauthorizedError();
    }
  }

  if (response?.status === 200) {
    isExpired = false;
    if (retry) {
      return response;
    }
    const textResponse = yield response.text();
    try {
      return JSON.parse(textResponse);
    } catch {
      return textResponse;
    }
  }

  if (response?.status === 400) {
    const data: Error400Model = yield response.json();
    throw new BadRequestError(
      data?.uiMessage || data?.message || "Bad Request Error"
    );
  }

  if (response?.status === 204) {
    yield delay(1000);
    throw new ErrorNoContent("No Data");
  }

  if (response?.status === 404) {
    throw new NotFoundRequestError();
  }

  if (response?.status === 500) {
    throw new ApiError("Server Error");
  }
}

function* fetchFileBase(
  method: string,
  resourceUrl: string,
  data: unknown = null,
  retry = false
): unknown {
  const token = yield call(getToken);
  const orgId = yield call(getOrg);
  const lng = yield call(getLang);
  const result = yield fetch(`${appSettings.apiBaseUrl}${resourceUrl}`, {
    method: method,
    headers: {
      Authorization: "Bearer " + token ?? "",
      Accept: "application/octet-stream",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-organization": orgId,
      "x-domino-lang": lng,
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  return yield handleResponseFile(result, method, resourceUrl, retry);
}

function* handleResponseFile(
  response: Response,
  method: string,
  resourceUrl: string,
  retry?: boolean
): unknown {
    if (response?.status === 401) {
      if (!retry) {
        isExpired = !isExpired;
        yield put(authSlice.actions.refreshToken());
        response = yield fetchFileBase(method, resourceUrl, true);
      } else {
        throw new UnauthorizedError();
      }
      
    }
  
    if (response?.status === 200) {
      isExpired = false;
      return response;
    }
  
    if (response?.status === 400) {
      const data: Error400Model = yield response.json();
      throw new BadRequestError(data?.uiMessage ?? data.message, data.validationSource?.flowId, data.validationSource?.nodeId);
    }
  
    if (response?.status === 204) {
      yield delay(1000);
      throw new ErrorNoContent("No Data");
    }
  
    if (response?.status === 404) {
      throw new NotFoundRequestError();
    }
  }

function* fetchFormBase(method: string, resourceUrl: string, form: FormData, retry = false): unknown {
  const token = yield call(getToken);
  const orgId = yield call(getOrg);
  const lng = yield call(getLang);

  const result = yield fetch(`${appSettings.apiBaseUrl}${resourceUrl}`, {
    method: method,
    headers: {
      Authorization: "Bearer " + token,
      Accept: "application/vnd.domino.v1+json",
      "x-domino-lang": lng,
      "x-domino-organization": orgId,
    },
    body: form,
  });

  return yield handleResponseForm(result, method, resourceUrl, form, retry);
}

function* handleResponseForm(
  response: Response,
  method: string,
  resourceUrl: string,
  form: FormData,
  retry?: boolean,
): unknown {
  if (response?.status === 401) {
    if (!retry) {
      isExpired = !isExpired;
      yield put(authSlice.actions.refreshToken());
      response = yield fetchFormBase(method, resourceUrl, form, true);
    } else {
      throw new UnauthorizedError();
    }
    
  }

  if (response?.status === 200) {
    isExpired = false;
    if (retry) {
      return response;
    }
    if (resourceUrl.startsWith("/file")) {
      const fileBlob = yield response.blob();
      try {
        return fileBlob;
      } catch {
        return fileBlob;
      }
    }
    const textResponse = yield response.text();
    try {
      return JSON.parse(textResponse);
    } catch {
      return textResponse;
    }
  }

  if (response?.status === 400) {
    const data: Error400Model = yield response.json();
    throw new BadRequestError(data?.uiMessage ?? data.message, data.validationSource?.flowId, data.validationSource?.nodeId);
  }

  if (response?.status === 204) {
    yield delay(1000);
    throw new ErrorNoContent("No Data");
  }

  if (response?.status === 404) {
    throw new NotFoundRequestError();
  }
}

