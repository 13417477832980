import { useTranslation } from "react-i18next";
import { Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useInput } from "../../hooks/useInput";
import { InputText } from "../../ui/InputText/InputText";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { createDataFile, selectIsLoading } from "../DataFiles/DataFilesSlice";
import { PATHS } from "../../providers/router/paths";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store";
import { Loader } from "../../ui/Loader/Loader";
import { Content } from "./components/Content/Content";
import s from "./CreateDataFiles.module.scss";
import { CreateBinaryFile, CreateTextFile } from "../DataFiles/DataFilesApi";

export const CreateDataFiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const [isFileInput, setIsFileInput] = useState<boolean>(false);
  const toggleIsFileInput = () => setIsFileInput(prev => !prev);

  const [name, changeName, clearName, setName] = useInput("");
  const [description, changeDescription, clearDescription] = useInput("");
  const [content, changeContent, clearContent] = useInput("");
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const changeCurrentFile = (file: File | null) => {
    if (!file) return;

    const splittedFileName = file.name.split(".");
    const fileName = splittedFileName.slice(0, splittedFileName.length - 1).join(".");

    setName(fileName);
    setCurrentFile(file);
  };

  useEffect(() => {
    setCurrentFile(file => {
      if (file) {
        const splittedFileName = file.name.split(".");
        const extension = "." + splittedFileName[splittedFileName.length - 1];

        return new File([file], name + extension, { type: file.type });
      }

      return file;
    });
  }, [name]);

  const handleCancel = () => navigate(`/${PATHS.dataFiles.root}`);

  const [isCreationStarted, setIsCreationStarted] = useState<boolean>(false);
  const handleSave = () => {
    if (!isFileInput ? name && content : name && currentFile) {
      let dataFile: CreateTextFile | CreateBinaryFile;
      if (!isFileInput) {
        dataFile = {
          name,
          description,
          data: content,
        } as CreateTextFile;
      } else {
        dataFile = {
          description,
          file: currentFile,
        } as CreateBinaryFile;
      }

      dispatch(createDataFile(dataFile));
      setIsCreationStarted(true);
    }
  };
  useEffect(() => {
    if (isCreationStarted && !isLoading) {
      navigate("/" + PATHS.dataFiles.root);
      setIsCreationStarted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const isTooltipDisabled = !isFileInput ? !!name && !!content : !!name && !!currentFile;

  return (
    <ChapterWrapper>
      {isLoading && <Loader />}
      <Flex direction="column" rowGap="16px" className={s.form}>
        <Text className={s.title}>{t("New Data File")}</Text>
        <Flex direction="column" rowGap="16px" className={s.fields}>
          <InputText
            value={name}
            onChange={changeName}
            label={t("Name")}
            placeholder={t("Enter name")}
            onClear={clearName}
            isDisabled={isFileInput && !currentFile}
          />
          <InputText
            value={description}
            onChange={changeDescription}
            label={t("Description")}
            placeholder={t("Enter description")}
            onClear={clearDescription}
          />
          <Content
            content={content}
            changeContent={changeContent}
            clearContent={clearContent}
            currentFile={currentFile}
            changeCurrentFile={changeCurrentFile}
            isFileInput={isFileInput}
            toggleIsFileInput={toggleIsFileInput}
          />
        </Flex>
        <Flex justifyContent="space-between" maxW="550px">
          <Button variant="dominoOutlineViolet" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Tooltip
            variant="dominoLight"
            label={!isFileInput ? t("Enter name and content to save data file") : t("Enter name and file to save data file")}
            isDisabled={isTooltipDisabled}
            hasArrow
            arrowSize={16}
            bg={"#FEF6DC"}
          >
            <Button
              variant="dominoViolet"
              onClick={handleSave}
              isDisabled={!isFileInput ? !name || !content : !name || !currentFile}
            >
              {t("Save")}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </ChapterWrapper>
  );
};
