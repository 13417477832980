import { Flex, Input, InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import { ChangeEventHandler, useId } from "react";
import s from "./InputText.module.scss";

interface Props extends InputProps {
  value: string;
  errorMessage?: string;
  placeholder: string;
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClear: () => void;
}

export const InputText = ({ value, onChange, placeholder, errorMessage, label, onClear, ...rest }: Props) => {
  const inputId = useId();

  return (
    <Flex maxW="550px">
      <InputWrapper label={label} errorMessage={errorMessage} inputId={inputId}>
        <InputGroup>
          <Input
            className={s.input}
            value={value}
            onChange={onChange}
            isInvalid={!!errorMessage}
            errorBorderColor="red.500"
            placeholder={placeholder}
            id={inputId}
            {...rest}
          />
          <InputRightElement>
            {value && <CrossIcon boxSize={1} cursor={"pointer"} className={s.crossIcon} onClick={onClear} />}
          </InputRightElement>
        </InputGroup>
      </InputWrapper>
    </Flex>
  );
};
