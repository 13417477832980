import { useTranslation } from "react-i18next";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import { Box, Button, Flex, Icon, Tooltip } from "@chakra-ui/react";
import { InputSelect } from "../../ui/InputSelect/InputSelect";
import { useSelect } from "../../hooks/useSelect";
import { useNavigate } from "react-router-dom";
import { InputText } from "../../ui/InputText/InputText";
import { useInput } from "../../hooks/useInput";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { ReactComponent as trashIcon } from "../../assets/icons/TrashGrey.svg";
import { ConfirmModal } from "../../ui/ConfirmModal/ConfirmModal";
import { useEffect, useState } from "react";
import { DataFilesModel, TextFileModel } from "./DataFilesModel";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { bringDataToOption } from "../../utils/bringDataToOption";
import {
  deleteDataFile,
  downloadFile,
  getDataFile,
  selectCurrentDataFile,
  selectIsLoading,
  updateDataFile,
} from "./DataFilesSlice";
import { PATHS } from "../../providers/router/paths";
import { useAppSelector } from "../../store";
import s from "./DataFiles.module.scss";
import { Loader } from "../../ui/Loader/Loader";
import { DragAndDrop } from "../../ui/DragAndDrop/DragAndDrop";
import { UpdateBinaryFile } from "./DataFilesApi";

const MAX_FILE_SIZE = 16777216;

interface Props {
  datas: DataFilesModel[];
}

export const DataFiles = ({ datas }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  const currentDataFile = useAppSelector(selectCurrentDataFile);
  const [wasChanges, setWasChanges] = useState<boolean>(false);
  const spyOnChange = () => setWasChanges(true);

  const [dataFiles, changeDataFiles, dataFilesValue, setDataFiles] = useSelect(
    bringDataToOption({ data: datas, isFirstChecked: true }),
  );
  const [description, changeDescription, clearDescription, setDescription] = useInput("", spyOnChange);
  const [data, changeData, clearData, setData] = useInput("", spyOnChange);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const changeCurrentFile = (file: File | null) => {
    spyOnChange();
    setCurrentFile(file);
  };

  const setDataFileData = () => {
    if (!(currentDataFile && Object.keys(currentDataFile).length > 0)) return;
    if ("data" in currentDataFile) {
      const { description, data } = currentDataFile;

      setDescription((description !== "null" ? description : "") ?? "");
      setData(data);
    } else {
      const { description, name, contentType } = currentDataFile;

      const splittedName = name.split(".");
      const extension = "." + splittedName.slice(splittedName.length - 1).join("");
      const fileKey = splittedName.slice(0, splittedName.length - 1).join(".");

      const newFile = new File([fileKey], fileKey + extension, { type: contentType });

      setDescription((description !== "null" ? description : "") ?? "");
      setCurrentFile(newFile);
    }
    setWasChanges(false);
  };

  useEffect(() => {
    if (currentDataFile) {
      const checkedData = datas.find(data => data.id === currentDataFile.id);
      const datasForOptions = bringDataToOption({
        data: datas,
        checkedIds: [checkedData ? currentDataFile.id : datas[0].id],
      });
      setDataFiles(datasForOptions);
      setWasChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas]);
  useEffect(() => {
    const checkedOption = dataFiles.find(dataFile => dataFile.isChecked);
    const selectedDataFile = datas.find(data => data.id === checkedOption?.id);

    selectedDataFile && dispatch(getDataFile({ id: selectedDataFile.id, type: selectedDataFile.type }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFiles]);
  useEffect(() => {
    if (currentDataFile && Object.keys(currentDataFile).length > 0) {
      setDataFileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataFile]);

  const editActions = {
    handleDiscard: () => setDataFileData(),
    handleSave: () => {
      if (wasChanges && currentDataFile) {
        if ("data" in currentDataFile) {
          const textFile: TextFileModel = {
            id: currentDataFile.id,
            name: dataFilesValue,
            description,
            data,
          };

          dispatch(updateDataFile(textFile));
        } else {
          if (currentFile) {
            const binaryFile: UpdateBinaryFile = {
              id: currentDataFile.id,
              file: currentFile,
              description,
            };

            dispatch(updateDataFile(binaryFile));
          }
        }
      }
    },
  };
  const isSaveButtonDisabled = !wasChanges || (currentDataFile && ("data" in currentDataFile ? !data : !currentFile));
  const isTooltipDisabled = !wasChanges || (currentDataFile && ("data" in currentDataFile ? !!data : !!currentFile));

  const handleDownload = () =>
    currentDataFile &&
    dispatch(
      downloadFile({
        dataFileId: currentDataFile.id,
        fileName: currentDataFile.name,
      }),
    );

  const additionalButton = { text: t("Add data files"), onClick: () => navigate(PATHS.dataFiles.newDataFile) };

  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const deleteModal = {
    handleOpen: () => setIsModalShown(true),
    handleClose: () => setIsModalShown(false),
    handleConfirm: () => {
      currentDataFile && dispatch(deleteDataFile(currentDataFile.id));
      deleteModal.handleClose();
    },
  };

  const isInputDisabled = !dataFilesValue;

  return (
    <ChapterWrapper>
      {isLoading && <Loader />}
      <Flex direction="column" rowGap="16px" className={s.form}>
        <Flex alignItems="center" alignSelf="end" columnGap="16px" pos="absolute" top="12px" right="28px" zIndex="200">
          <Button
            onClick={editActions.handleDiscard}
            variant="solid"
            color="mainRed"
            colorScheme="whiteAlpha"
            isDisabled={!wasChanges}
          >
            {t("Discard changes")}
          </Button>
          <Tooltip
            variant="dominoLight"
            label={
              currentDataFile &&
              ("data" in currentDataFile ? t("Enter content to save changes") : t("Add a file to save changes"))
            }
            isDisabled={isTooltipDisabled}
            hasArrow
            arrowSize={16}
            bg={"#FEF6DC"}
          >
            <Button onClick={editActions.handleSave} variant="dominoViolet" isDisabled={isSaveButtonDisabled}>
              {t("Save")}
            </Button>
          </Tooltip>
        </Flex>
        <InputSelect
          label={t("Data Files")}
          onChange={changeDataFiles}
          options={dataFiles}
          placeholder={t("Select Data Files")}
          value={dataFilesValue}
          additionalButton={additionalButton}
        />
        <InputText
          label={t("Description")}
          onChange={changeDescription}
          onClear={clearDescription}
          placeholder={t("Enter description")}
          value={description}
          isDisabled={isInputDisabled}
        />
        {currentDataFile &&
          ("data" in currentDataFile ? (
            <InputTextarea
              label={t("Content")}
              onChange={changeData}
              onClear={clearData}
              placeholder={t("Enter content")}
              value={data}
              isDisabled={isInputDisabled}
            />
          ) : (
            <Box className={s.dragAndDropWrapper}>
              <DragAndDrop
                currentFile={currentFile}
                changeCurrentFile={changeCurrentFile}
                extensions=".pdf"
                maxSize={MAX_FILE_SIZE}
                isPossibleReplace
                isPossibleClear
                isDownloadable={!wasChanges}
                handleDownload={handleDownload}
              />
            </Box>
          ))}
        <Icon
          color="#8592A3"
          _hover={{ color: "#EB5038", bg: "#F1F4F9", borderRadius: "4px" }}
          cursor="pointer"
          as={trashIcon}
          boxSize="24px"
          onClick={deleteModal.handleOpen}
        />
        <ConfirmModal
          cancelText={t("Cancel")}
          confirmText={t("Delete")}
          isOpen={isModalShown}
          onCancel={deleteModal.handleClose}
          onClose={deleteModal.handleClose}
          onConfirm={deleteModal.handleConfirm}
          text={t("Delete data file?")}
        />
      </Flex>
    </ChapterWrapper>
  );
};
