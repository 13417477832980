import { DataFiles } from "../DataFiles/DataFiles";
import { useAppSelector } from "../../store";
import { selectDataFiles } from "../DataFiles/DataFilesSlice";
import { useTranslation } from "react-i18next";
import { EmptyView } from "@anthill/domino-ui-base";

export const DataFilesView = () => {
  const { t } = useTranslation();
  const dataFiles = useAppSelector(selectDataFiles);

  return (
    <>
      {dataFiles && dataFiles.length !== 0 ? (
        <DataFiles datas={dataFiles} />
      ) : (
        <EmptyView
          title={t("You haven't added any Data Files yet")}
          description={t("To get started, create an Data File")}
          createLink="/data-files/new-data-file"
          createButtonText={t("Create a new Data File")}
        />
      )}
    </>
  );
};
