import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store";
import { Agent } from "../Agent/Agent";
import { selectAgents } from "../Agent/agentSlice";
import { selectDataFiles } from "../DataFiles/DataFilesSlice";
import { EmptyView } from "@anthill/domino-ui-base";

export const AgentView = () => {
  const { t } = useTranslation();
  const agents = useAppSelector(selectAgents);
  const dataFiles = useAppSelector(selectDataFiles);

  return (
    <main>
      {agents.length > 0 ? (
        <Agent agents={agents} dataFiles={dataFiles} />
      ) : (
        <EmptyView
          title={t("You haven't added any agents yet")}
          description={t("To get started, create an agent")}
          createLink="/agents/new-agent"
          createButtonText={t("Create a new agent")}
        />
      )}
    </main>
  );
};
