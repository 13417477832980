import { TFunction } from "i18next";

interface SelectFileMessage {
  extensions: string;
  t: TFunction<"translation", undefined>;
}
export function selectFileMessage({ extensions, t }: SelectFileMessage): string {
  const extArr = extensions.split(",");

  if (extArr.length > 1) {
    return (
      t("Select one of") +
      " " +
      extArr.reduce((acc, curr, index) => acc + (index !== 0 ? ", " : "") + curr.slice(1).toUpperCase(), "") +
      " " +
      t("files")
    );
  } else {
    return t("Select a") + ` ${extArr[0].slice(1).toUpperCase()} ` + t("file");
  }
}
