import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import { DragAndDrop } from "../../../../ui/DragAndDrop/DragAndDrop";
import { InputTextarea } from "../../../../ui/InputTextarea/InputTextarea";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import s from "./Content.module.scss";

const MAX_FILE_SIZE = 16777216;

interface Props {
  content: string;
  changeContent: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  clearContent: () => void;
  currentFile: File | null;
  changeCurrentFile: (file: File | null) => void;
  isFileInput: boolean;
  toggleIsFileInput: () => void;
}
export const Content = ({
  content,
  changeContent,
  clearContent,
  currentFile,
  changeCurrentFile,
  isFileInput,
  toggleIsFileInput,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" rowGap="12px">
      <Flex direction="row" alignItems="center" columnGap="8px">
        <Text className={`${s.variant} ${!isFileInput && s.variant__active}`}>{t("Manual")}</Text>
        <Switch variant="dominoDefaultGreen" isChecked={isFileInput} onChange={toggleIsFileInput} />
        <Text className={`${s.variant} ${isFileInput && s.variant__active}`}>{t("File")}</Text>
      </Flex>
      {!isFileInput ? (
        <InputTextarea value={content} onChange={changeContent} placeholder={t("Enter content")} onClear={clearContent} />
      ) : (
        <Box className={s.dragAndDrop}>
          <DragAndDrop
            currentFile={currentFile}
            changeCurrentFile={changeCurrentFile}
            extensions=".pdf"
            maxSize={MAX_FILE_SIZE}
            isPossibleReplace
          />
        </Box>
      )}
    </Flex>
  );
};
