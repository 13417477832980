import { Tabs, TabList, Tab, Flex } from "@chakra-ui/react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { PATHS } from "../../providers/router/paths";
import s from "./Header.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index: number) => setTabIndex(index);

  const isNewAgentPage = location.pathname === `/${PATHS.agents.root}/${PATHS.agents.newAgent}`;
  const isNewDataFilePage = location.pathname === `/${PATHS.dataFiles.root}/${PATHS.dataFiles.newDataFile}`;

  const handlePathChange = (path: string) => {
    if (isNewAgentPage || isNewDataFilePage) {
      if (path === PATHS.agents.root) {
        navigate(`/${PATHS.agents.root}/${PATHS.agents.newAgent}`);
      } else if (path === PATHS.dataFiles.root) {
        navigate(`/${PATHS.dataFiles.root}/${PATHS.dataFiles.newDataFile}`);
      }
    } else {
      navigate(path);
    }
  };
  useEffect(() => {
    tabIndex === 0 ? handlePathChange(PATHS.agents.root) : handlePathChange(PATHS.dataFiles.root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    if (isNewDataFilePage) {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewDataFilePage]);
  return (
    <>
      <Flex className={s.header}>
        <Tabs index={tabIndex} onChange={handleTabChange}>
          <TabList>
            <Tab fontSize="20px">{t("Agents")}</Tab>
            <Tab fontSize="20px">{t("Data files")}</Tab>
          </TabList>
        </Tabs>
      </Flex>
      <Outlet />
    </>
  );
};
