import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DataFileModel, DataFilesModel, TextFileModel } from "./DataFilesModel";
import { DataFilesState } from "./DataFilesState";
import { ApplicationState } from "../../ApplicationState";
import { CreateBinaryFile, CreateTextFile, UpdateBinaryFile } from "./DataFilesApi";
import { DownloadFile, GetDataFile } from "./DataFilesSaga";

const initialState: DataFilesState = {
  dataFiles: [],
  currentDataFile: {} as DataFileModel,
  isLoading: false,
};

export const DataFilesSlice = createSlice({
  name: "dataFilesSlice",
  initialState,
  reducers: {
    getDataFiles: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getDataFilesCompleted: (state, action: PayloadAction<DataFilesModel[]>) => {
      return {
        ...state,
        dataFiles: action.payload,
        isLoading: false,
      };
    },
    createDataFile: (state, action: PayloadAction<CreateTextFile | CreateBinaryFile>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getDataFile: (state, action: PayloadAction<GetDataFile>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getCurrentDataFileCompleted: (state, action: PayloadAction<DataFileModel>) => {
      return {
        ...state,
        currentDataFile: action.payload,
        isLoading: false,
      };
    },
    updateDataFile: (state, action: PayloadAction<TextFileModel | UpdateBinaryFile>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteDataFile: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    clearDataFile: state => {
      return {
        ...state,
        currentDataFile: undefined,
      };
    },
    downloadFile: (state, action: PayloadAction<DownloadFile>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    downloadFileCompleted: state => {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetLoading: state => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getDataFiles,
  createDataFile,
  updateDataFile,
  clearDataFile,
  deleteDataFile,
  getDataFile,
  downloadFile,
  downloadFileCompleted,
  resetLoading,
} = DataFilesSlice.actions;

export const selectDataFiles = (state: ApplicationState) => state.app.dataFilesState.dataFiles;
export const selectCurrentDataFile = (state: ApplicationState) => state.app.dataFilesState.currentDataFile;
export const selectIsLoading = (state: ApplicationState) => state.app.dataFilesState.isLoading;
