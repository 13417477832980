import { baseApi } from "@anthill/domino-ui-base";
import { BinaryFileModel, TextFileModel } from "./DataFilesModel";
import { BinaryFileData, DataFilesData, TextFileData } from "./DataFilesData";

export type CreateTextFile = Pick<TextFileModel, "name" | "description" | "data">;
export type CreateBinaryFile = Pick<BinaryFileModel, "description"> & { file: File };
export type UpdateBinaryFile = Pick<BinaryFileModel, "description" | "id"> & { file: File };

export const getDataFiles = (): DataFilesData => {
  return baseApi.httpGet(`/dataFiles`) as DataFilesData;
};

export const getTextFile = (id: string): TextFileData => {
  return baseApi.httpGet(`/dataFiles/text/${id}`) as TextFileData;
};
export const getBinaryFile = (id: string): BinaryFileData => {
  return baseApi.httpGet(`/dataFiles/binary/${id}`) as BinaryFileData;
};

export const createTextFile = (dataFile: CreateTextFile): TextFileData => {
  return baseApi.httpPost(`/dataFiles`, dataFile) as TextFileData;
};
export const createBinaryFile = (dataFile: CreateBinaryFile): BinaryFileData => {
  const formData = new FormData();
  Object.keys(dataFile).forEach(key => formData.append(key, dataFile[key as keyof CreateBinaryFile]));

  return baseApi.httpPostForm(`/dataFiles/upload`, formData) as BinaryFileData;
};

export const updateTextFile = (dataFile: TextFileModel) => {
  return baseApi.httpPut(`/dataFiles/text/${dataFile.id}`, dataFile);
};
export const updateBinaryFile = (dataFile: UpdateBinaryFile): BinaryFileData => {
  const formData = new FormData();
  Object.keys(dataFile).forEach(key => formData.append(key, dataFile[key as keyof UpdateBinaryFile]));

  return baseApi.httpPutForm(`/dataFiles/binary/${dataFile.id}`, formData) as BinaryFileData;
};

export const deleteDataFile = (dataFilesId: string): void => {
  return baseApi.httpDelete(`/dataFiles/${dataFilesId}`) as void;
};

export const downloadFile = (dataFileId: string): unknown => {
  return baseApi.httpGetFile(`/dataFiles/download/${dataFileId}`);
};
