import { Flex, Icon, Text } from "@chakra-ui/react";
import { ReactComponent as copy } from "../../assets/icons/copyIcon.svg";
import s from "./CopyField.module.scss";
import { notificationSlice, useAppDispatch } from "@anthill/domino-ui-base";

interface Props {
  value: string;
}

export const CopyField = ({ value }: Props) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    navigator.clipboard.writeText(value);
    dispatch(
      notificationSlice.actions.notify({
        message: "Value copied successfully!",
        type: "success",
      }),
    );
  };

  return (
    <Flex alignItems="center" justifyContent="space-between" className={s.field}>
      <Text w="500px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {value}
      </Text>
      <Icon as={copy} color="#8592A3" alt="CopyIcon" boxSize="20px" _hover={{ color: "#3E5E95" }} onClick={handleClick} />
    </Flex>
  );
};
