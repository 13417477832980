import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AgentState } from "./AgentState";
import { AgentModel } from "./AgentModel";
import { ApplicationState } from "../../ApplicationState";
import { CreateAgentType } from "./AgentApi";

const initialState: AgentState = {
  agents: [],
  isLoading: false,
};

export const agentSlice = createSlice({
  name: "agentSlice",
  initialState,
  reducers: {
    getAgents: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAgentsCompleted: (state, action: PayloadAction<AgentModel[]>) => {
      return {
        ...state,
        agents: action.payload,
        isLoading: false,
      };
    },
    createAgent: (state, action: PayloadAction<CreateAgentType>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    createAgentCompleted: (state, action: PayloadAction<AgentModel>) => {
      return {
        ...state,
        agents: [action.payload, ...state.agents],
        isLoading: false,
      };
    },
    updateAgent: (state, action: PayloadAction<AgentModel>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateAgentCompleted: (state, action: PayloadAction<AgentModel>) => {
      const agentIndex = state.agents.findIndex(agent => agent.id === action.payload.id);

      if (agentIndex > -1) {
        const agentsCopy = [...state.agents];
        agentsCopy[agentIndex] = action.payload;

        return {
          ...state,
          agents: agentsCopy,
          isLoading: false,
        };
      }

      return state;
    },
    deleteAgent: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteAgentCompleted: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        agents: state.agents.filter(agent => agent.id !== action.payload),
        isLoading: false,
      };
    },
    resetLoading: state => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getAgents,
  getAgentsCompleted,
  createAgent,
  createAgentCompleted,
  updateAgent,
  updateAgentCompleted,
  deleteAgent,
  deleteAgentCompleted,
  resetLoading,
} = agentSlice.actions;

export const selectAgents = (state: ApplicationState) => state.app.agentState.agents;
export const selectIsLoading = (state: ApplicationState) => state.app.agentState.isLoading;
