import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useInput } from "../../hooks/useInput";
import { InputText } from "../../ui/InputText/InputText";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { useSelect } from "../../hooks/useSelect";
import { InputSelect } from "../../ui/InputSelect/InputSelect";
import s from "./CreateAgent.module.scss";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { CreateAgentType } from "../Agent/AgentApi";
import { createAgent, selectIsLoading } from "../Agent/agentSlice";
import { PATHS } from "../../providers/router/paths";
import { useAppSelector } from "../../store";
import { selectDataFiles } from "../DataFiles/DataFilesSlice";
import { bringDataToOption } from "../../utils/bringDataToOption";
import { useEffect, useState } from "react";
import { Loader } from "../../ui/Loader/Loader";

export const CreateAgent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const dataFiles = useAppSelector(selectDataFiles);

  const [name, changeName, clearName] = useInput("");
  const [description, changeDescription, clearDescription] = useInput("");
  const [prompt, changePrompt, clearPrompt] = useInput("");
  const [dataFilesOptions, changeDataFiles, valueDataFiles] = useSelect(bringDataToOption({ data: dataFiles }), true);

  const createDataFiles = () => navigate(`/${PATHS.dataFiles.root}/${PATHS.dataFiles.newDataFile}`);
  const additionalButton = { text: t("Add data files"), onClick: createDataFiles };

  const handleCancel = () => navigate(`/${PATHS.agents.root}`);

  const [isCreationStarted, setIsCreationStarted] = useState<boolean>(false);
  const handleSave = () => {
    if (name) {
      const agent: CreateAgentType = {
        name,
        description,
        prompt,
        dataFileIds: dataFilesOptions?.filter(dataFile => dataFile.isChecked)?.map(dataFile => dataFile.id),
      };

      dispatch(createAgent(agent));
      setIsCreationStarted(true);
    }
  };
  useEffect(() => {
    if (isCreationStarted && !isLoading) {
      navigate("/" + PATHS.agents.root);
      setIsCreationStarted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <ChapterWrapper>
      {isLoading && <Loader />}
      <Flex direction="column" rowGap="16px" className={s.form}>
        <Text className={s.title}>{t("New Agent")}</Text>
        <Flex direction="column" rowGap="16px" className={s.fields}>
          <InputText value={name} onChange={changeName} label={t("Name")} placeholder={t("Enter name")} onClear={clearName} />
          <InputText
            value={description}
            onChange={changeDescription}
            label={t("Description")}
            placeholder={t("Enter description")}
            onClear={clearDescription}
          />
          <InputTextarea
            value={prompt}
            onChange={changePrompt}
            label={t("Prompt")}
            placeholder={t("Enter prompt")}
            onClear={clearPrompt}
          />
          <InputSelect
            value={valueDataFiles}
            options={dataFilesOptions}
            onChange={changeDataFiles}
            label={t("Data Files")}
            placeholder={t("Select data Files")}
            isMultiselect={true}
            additionalButton={additionalButton}
          />
        </Flex>
        <Flex justifyContent="space-between" maxW="550px">
          <Button variant="dominoOutlineViolet" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
          <Button variant="dominoViolet" onClick={handleSave} isDisabled={!name}>
            {t("Save")}
          </Button>
        </Flex>
      </Flex>
    </ChapterWrapper>
  );
};
