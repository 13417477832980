import { notify } from "@anthill/domino-ui-base/dist/notifications/NotificationSlice";
import { AppDispatch } from "@anthill/domino-ui-base/dist/store";
import { TFunction } from "i18next";
import { ChangeEvent, Dispatch, DragEvent, SetStateAction } from "react";
import { selectFileMessage } from "./utils";

interface HandleDrag {
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  currentFile: File | null;
  isPossibleReplace: boolean;
}
export const handleDrag = ({ currentFile, setIsDragging, isPossibleReplace }: HandleDrag) => {
  return function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!currentFile || isPossibleReplace) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setIsDragging(true);
      } else if (e.type === "dragleave" || e.type === "dragend") {
        setIsDragging(false);
      }
    }
  };
};

interface ChooseFile {
  changeCurrentFile: (file: File) => void;
  dispatch: AppDispatch;
  maxSize: number;
  t: TFunction<"translation", undefined>;
  extensions: string;
}
export const chooseFile = ({ changeCurrentFile, dispatch, maxSize, t, extensions }: ChooseFile) => {
  return function (e: ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];
    checkingFile({ file, dispatch, extensions, maxSize, changeCurrentFile, t });
  };
};

interface OnDropFile {
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  extensions: string;
  maxSize: number;
  changeCurrentFile: (file: File) => void;
  dispatch: AppDispatch;
  t: TFunction<"translation", undefined>;
}
export const onDropFile = ({ setIsDragging, extensions, maxSize, changeCurrentFile, dispatch, t }: OnDropFile) => {
  return function (event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer?.files[0];
    checkingFile({ file, dispatch, extensions, maxSize, changeCurrentFile, t });
  };
};

interface CheckingFile {
  file: File | undefined;
  extensions: string;
  maxSize: number;
  changeCurrentFile: (file: File) => void;
  dispatch: AppDispatch;
  t: TFunction<"translation", undefined>;
}
function checkingFile({ file, extensions, maxSize, changeCurrentFile, dispatch, t }: CheckingFile) {
  if (file) {
    const [, ext] = file.type.split("/");
    const splittedFileName = file.name.split(".");
    if (extensions.includes(ext) || extensions.includes(splittedFileName[splittedFileName.length - 1])) {
      if (file.size <= maxSize) {
        changeCurrentFile(file);
      } else if (file.size > maxSize) {
        dispatch(notify({ message: t("File is too big"), type: "error" }));
      }
    } else {
      dispatch(notify({ message: selectFileMessage({ extensions, t }), type: "error" }));
    }
  }
}
