import { Dispatch, SetStateAction, useEffect, useState } from "react";

export interface Option {
  name: string;
  isChecked: boolean;
  id: string;
}

type ReturnValue = [Option[], (option: Option) => void, string, Dispatch<SetStateAction<Option[]>>];

export function useSelect(init: Option[], isMultiselect?: boolean, onChangeSpy?: () => void): ReturnValue {
  const [options, setOptions] = useState<Option[]>(init);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(
      options?.reduce((acc, curr) => (curr?.isChecked ? acc + (acc ? ", " : "") + (acc ? curr?.name : curr?.name) : acc), ""),
    );
  }, [options]);

  const onChange = (option: Option) => {
    if (isMultiselect) {
      setOptions(options?.map(opt => (opt?.id === option?.id ? { ...opt, isChecked: !opt?.isChecked } : opt)));
    } else {
      setOptions(options?.map(opt => (opt?.id === option?.id ? { ...opt, isChecked: true } : { ...opt, isChecked: false })));
    }

    onChangeSpy && onChangeSpy();
  };

  return [options, onChange, value, setOptions];
}
