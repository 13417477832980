import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import { AgentView } from "../../components/AgentView/AgentView";
import { CreateAgent } from "../../components/CreateAgent/CreateAgent";
import { DataFilesView } from "../../components/DataFilesView/DataFilesView";
import { CreateDataFiles } from "../../components/CreateDataFiles/CreateDataFiles";
import { Header } from "../../components/Header/Header";
import { PATHS } from "./paths";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={PATHS.root} element={<Header />}>
      <Route path={PATHS.agents.root}>
        <Route index element={<AgentView />} />
        <Route path={PATHS.agents.newAgent} element={<CreateAgent />} />
      </Route>
      <Route path={PATHS.dataFiles.root}>
        <Route index element={<DataFilesView />} />
        <Route path={PATHS.dataFiles.newDataFile} element={<CreateDataFiles />} />
      </Route>
      <Route index element={<Navigate to={PATHS.agents.root} />} />
    </Route>,
  ),
);
