import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react";
import s from "./ConfirmModal.module.scss";
import { useEffect } from "react";
import { AppSettings } from "../../AppSettings";

declare const appSettings: AppSettings;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelText: string;
  confirmText: string;
}

export const ConfirmModal = ({ isOpen, onClose, text, cancelText, confirmText, onCancel, onConfirm }: Props) => {
  useEffect(() => {
    if (isOpen) {
      window.parent.postMessage(
        { type: "EXEC_PLUGIN", message: { pluginName: "aiPlugin", methodName: "turnOnModal" } },
        appSettings.appBaseUrl,
      );
    } else {
      window.parent.postMessage(
        { type: "EXEC_PLUGIN", message: { pluginName: "aiPlugin", methodName: "turnOffModal" } },
        appSettings.appBaseUrl,
      );
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className={s.modal} boxShadow="none">
        <ModalBody>
          <Text className={s.text}>{text}</Text>
        </ModalBody>
        <ModalFooter className={s.footer}>
          <Button onClick={onCancel} variant="dominoOutlineViolet">
            {cancelText}
          </Button>
          <Button onClick={onConfirm} variant="dominoPrimaryRed">
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
