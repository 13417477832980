import { Option } from "../hooks/useSelect";
import { AgentModel } from "../components/Agent/AgentModel";
import { DataFilesModel } from "../components/DataFiles/DataFilesModel";

interface Params {
  data: AgentModel[] | DataFilesModel[];
  checkedIds?: string[];
  isFirstChecked?: boolean;
}

export function bringDataToOption({ data, checkedIds, isFirstChecked }: Params): Option[] {
  if (!Array.isArray(data)) {
    console.error("Data is not an array:", data);
    return [];
  }

  return data.map((item, index) => ({
    id: item.id,
    name: item.name,
    isChecked: isFirstChecked ? index === 0 : !!(checkedIds && checkedIds.includes(item.id)),
  }));
}
