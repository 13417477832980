import { call, put, takeLatest } from "redux-saga/effects";
import { agentSlice } from "./agentSlice";
import { handleException, loadingSlice, notificationSlice } from "@anthill/domino-ui-base";
import * as AgentApi from "./AgentApi";
import { AgentModel } from "./AgentModel";
import { PayloadAction } from "@reduxjs/toolkit";

export function* agentSaga() {
  yield takeLatest(agentSlice.actions.getAgents, getAgents);
  yield takeLatest(agentSlice.actions.createAgent, createAgent);
  yield takeLatest(agentSlice.actions.updateAgent, updateAgent);
  yield takeLatest(agentSlice.actions.deleteAgent, deleteAgent);
}

export function* getAgents() {
  try {
    yield put(loadingSlice.actions.addLoadingState("getAgents"));
    const data: AgentModel[] = yield call(AgentApi.getAgents);
    yield put(agentSlice.actions.getAgentsCompleted(data));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(loadingSlice.actions.completedLoadingState("getAgents"));
  }
}

export function* createAgent(action: PayloadAction<AgentApi.CreateAgentType>) {
  try {
    const data: AgentModel = yield call(AgentApi.createAgent, action.payload);
    yield put(agentSlice.actions.createAgentCompleted(data));
  } catch (e: unknown) {
    yield handleException(e);
    yield put(agentSlice.actions.resetLoading());
  }
}

export function* updateAgent(action: PayloadAction<AgentModel>) {
  try {
    const data: AgentModel = yield call(AgentApi.updateAgent, action.payload);
    yield put(agentSlice.actions.updateAgentCompleted(data));
    yield put(
      notificationSlice.actions.notify({
        message: "Value saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
    yield put(agentSlice.actions.resetLoading());
  }
}

export function* deleteAgent(action: PayloadAction<string>) {
  try {
    yield call(AgentApi.deleteAgent, action.payload);
    yield put(agentSlice.actions.deleteAgentCompleted(action.payload));
  } catch (e: unknown) {
    yield handleException(e);
    yield put(agentSlice.actions.resetLoading());
  }
}
