import { useId } from "react";
import { InputWrapper } from "../InputWrapper/InputWrapper";
import { Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text, Checkbox } from "@chakra-ui/react";
import { Option } from "../../hooks/useSelect";
import { ReactComponent as ArrowDrop } from "../../assets/icons/ArrowDrop.svg";
import { ReactComponent as Plus } from "../../assets/icons/GreyPlus.svg";

interface AdditionalButton {
  text: string;
  onClick: () => void;
}

interface Props {
  options: Option[];
  onChange: (option: Option) => void;
  value: string;
  errorMessage?: string;
  placeholder: string;
  label: string;
  isMultiselect?: boolean;
  additionalButton?: AdditionalButton;
  isDisabled?: boolean;
}

export const InputSelect = ({
  options,
  onChange,
  value,
  errorMessage,
  placeholder,
  label,
  isMultiselect,
  additionalButton,
  isDisabled = false,
}: Props) => {
  const inputId = useId();

  return (
    <Flex maxW="550px">
      <InputWrapper label={label} errorMessage={errorMessage} inputId={inputId}>
        <Menu variant="dominoBroadcastMenu" matchWidth closeOnSelect={!isMultiselect}>
          <MenuButton
            as={Button}
            variant="dominoDefaultMenuBtn"
            _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
            rightIcon={<ArrowDrop />}
            isDisabled={isDisabled}
          >
            {value ? value : <Text color="#8592A3">{placeholder}</Text>}
          </MenuButton>
          <MenuList>
            {options?.map(option => (
              <MenuItem
                key={option.id}
                onClick={e => {
                  const target = e.target as HTMLElement;
                  if (target.tagName === "DIV" || target.tagName === "INPUT" || target.tagName === "P") {
                    onChange(option);
                  }
                }}
              >
                <Flex align="center" gap="8px" w="100%">
                  {isMultiselect && <Checkbox variant="dominoGreen" p="4px 0 4px" isChecked={option.isChecked} />}
                  <Text color="black" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {option.name}
                  </Text>
                </Flex>
              </MenuItem>
            ))}
            {additionalButton && (
              <MenuItem onClick={additionalButton.onClick}>
                <Flex w="100%" gap="8px" align="center">
                  <Icon as={Plus} boxSize="20px" />
                  <Text>{additionalButton.text}</Text>
                </Flex>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </InputWrapper>
    </Flex>
  );
};
