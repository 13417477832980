import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { AuthFrameContainer, Loader, NotificationContainer, selectLoadingState, useAppDispatch } from "@anthill/domino-ui-base";
import { router } from "./providers/router/router";
import { useAppSelector } from "./store";
import { getAgents, selectIsLoading as selectIsAgentsLoading } from "./components/Agent/agentSlice";
import { getDataFiles, selectIsLoading as selectIsDataFilesLoading } from "./components/DataFiles/DataFilesSlice";
import "./App.css";
import { AppSettings } from "./AppSettings";

declare const appSettings: AppSettings;

function App() {
  const dispatch = useAppDispatch();
  const isAgentsLoading = useAppSelector(selectIsAgentsLoading);
  const isDataFilesLoading = useAppSelector(selectIsDataFilesLoading);
  const isLoadingFromState = useAppSelector(selectLoadingState);

  useEffect(() => {
    dispatch(getAgents());
    dispatch(getDataFiles());
    window.parent.postMessage({ type: "LOAD_SCRIPT", message: { pluginId: "plugin.js" } }, appSettings.appBaseUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isLoadingFromState.length > 0 && (isAgentsLoading || isDataFilesLoading);

  return (
    <>
      {isLoading ? <Loader scope={isAgentsLoading ? "getAgents" : "getDataFiles"} /> : <RouterProvider router={router} />}
      <AuthFrameContainer pluginName="ai" />
      <NotificationContainer />
    </>
  );
}

export default App;
