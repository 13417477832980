import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { InputSelect } from "../../ui/InputSelect/InputSelect";
import { InputText } from "../../ui/InputText/InputText";
import { InputTextarea } from "../../ui/InputTextarea/InputTextarea";
import { useTranslation } from "react-i18next";
import { useSelect } from "../../hooks/useSelect";
import { useEffect, useState } from "react";
import { useInput } from "../../hooks/useInput";
import { useNavigate } from "react-router-dom";
import { ReactComponent as trashIcon } from "../../assets/icons/TrashGrey.svg";
import { InputWrapper } from "../../ui/InputWrapper/InputWrapper";
import { CopyField } from "../../ui/CopyField/CopyField";
import { ConfirmModal } from "../../ui/ConfirmModal/ConfirmModal";
import { AgentModel } from "./AgentModel";
import { bringDataToOption } from "../../utils/bringDataToOption";
import { DataFilesModel } from "../DataFiles/DataFilesModel";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { deleteAgent, selectIsLoading, updateAgent } from "./agentSlice";
import s from "./Agent.module.scss";
import { ChapterWrapper } from "../../ui/ChapterWrapper/ChapterWrapper";
import { PATHS } from "../../providers/router/paths";
import { Loader } from "../../ui/Loader/Loader";
import { useAppSelector } from "../../store";

interface Props {
  agents: AgentModel[];
  dataFiles: DataFilesModel[];
}

export const Agent = ({ agents, dataFiles }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  const [selectedAgent, setSelectedAgent] = useState<AgentModel>(agents[0]);
  const [wasChanges, setWasChanges] = useState<boolean>(false);
  const spyOnChange = () => setWasChanges(true);

  const [agentsOptions, changeAgents, agentsValue, setAgentsOptions] = useSelect([]);
  const [token, setToken] = useState<string>("");
  const [description, changeDescription, clearDescription, setDescription] = useInput("", spyOnChange);
  const [prompt, changePrompt, clearPrompt, setPrompt] = useInput("", spyOnChange);
  const [dataFilesOptions, changeDataFiles, dataFilesValue, setDataFilesOptions] = useSelect([], true, spyOnChange);

  const setAgentData = () => {
    const { description, prompt, dataFileIds, token } = selectedAgent;
    const dataFilesForOptions = bringDataToOption({ data: dataFiles, checkedIds: dataFileIds });

    setDescription(description);
    setPrompt(prompt);
    setDataFilesOptions(dataFilesForOptions);
    setWasChanges(false);
    setToken(token);
  };

  useEffect(() => {
    setAgentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgent]);
  useEffect(() => {
    const checkedAgent = agents.find(agent => agent.id === selectedAgent.id);
    const agentsForOptions = bringDataToOption({
      data: agents,
      checkedIds: [checkedAgent ? selectedAgent.id : agents[0].id],
    });
    setAgentsOptions(agentsForOptions);
    checkedAgent && setSelectedAgent(checkedAgent);
    setWasChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents]);
  useEffect(() => {
    const selectedAgent: AgentModel | undefined = agents.find(agent => agent.name === agentsValue);

    selectedAgent && setSelectedAgent(selectedAgent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsValue]);

  const editActions = {
    handleDiscard: () => setAgentData(),
    handleSave: () => {
      if (wasChanges) {
        const agent: AgentModel = {
          id: selectedAgent.id,
          name: agentsValue,
          description,
          prompt,
          token,
          dataFileIds: dataFilesOptions?.filter(dataFile => dataFile?.isChecked)?.map(dataFile => dataFile?.id),
        };

        dispatch(updateAgent(agent));
      }
    },
  };

  const additionalButtons = {
    agent: { text: t("Add agent"), onClick: () => navigate(PATHS.agents.newAgent) },
    dataFile: { text: t("Add Data Files"), onClick: () => navigate(`/${PATHS.dataFiles.root}/${PATHS.dataFiles.newDataFile}`) },
  };

  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const deleteModal = {
    handleOpen: () => setIsModalShown(true),
    handleClose: () => setIsModalShown(false),
    handleConfirm: () => {
      dispatch(deleteAgent(selectedAgent.id));
      deleteModal.handleClose();
    },
  };

  const isInputDisabled = !agentsValue;

  return (
    <ChapterWrapper>
      {isLoading && <Loader />}
      <Flex direction="column" rowGap="16px" className={s.form}>
        <Flex alignItems="center" alignSelf="end" columnGap="16px" pos="absolute" top="12px" right="28px" zIndex="200">
          <Button
            onClick={editActions.handleDiscard}
            variant="solid"
            color="mainRed"
            colorScheme="whiteAlpha"
            isDisabled={!wasChanges}
          >
            {t("Discard changes")}
          </Button>
          <Button onClick={editActions.handleSave} variant="dominoViolet" isDisabled={!wasChanges}>
            {t("Save")}
          </Button>
        </Flex>

        <Box>
          <InputSelect
            label={t("Agent")}
            onChange={changeAgents}
            options={agentsOptions}
            placeholder={t("Select agent")}
            value={agentsValue}
            additionalButton={additionalButtons.agent}
          />
        </Box>
        <Box>
          <InputWrapper label={t("Token")}>
            <CopyField value={token} />
          </InputWrapper>
        </Box>
        <InputText
          label={t("Description")}
          onChange={changeDescription}
          onClear={clearDescription}
          placeholder={t("Enter description")}
          value={description}
          isDisabled={isInputDisabled}
        />
        <InputTextarea
          label={t("Prompt")}
          onChange={changePrompt}
          onClear={clearPrompt}
          placeholder={t("Enter prompt")}
          value={prompt}
          isDisabled={isInputDisabled}
        />
        <InputSelect
          label={t("Data Files")}
          onChange={changeDataFiles}
          options={dataFilesOptions}
          placeholder={t("Select Data Files")}
          value={dataFilesValue}
          additionalButton={additionalButtons.dataFile}
          isMultiselect
          isDisabled={isInputDisabled}
        />
        <Icon
          color="#8592A3"
          _hover={{ color: "#EB5038", bg: "#F1F4F9", borderRadius: "4px" }}
          cursor="pointer"
          as={trashIcon}
          boxSize="24px"
          onClick={deleteModal.handleOpen}
        />
        <ConfirmModal
          cancelText={t("Cancel")}
          confirmText={t("Delete")}
          isOpen={isModalShown}
          onCancel={deleteModal.handleClose}
          onClose={deleteModal.handleClose}
          onConfirm={deleteModal.handleConfirm}
          text={t("Delete agent?")}
        />
      </Flex>
    </ChapterWrapper>
  );
};
