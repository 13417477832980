import { call, put, select, takeLatest } from "redux-saga/effects";
import * as DataFilesApi from "./DataFilesApi";
import { DataFilesSlice, selectDataFiles } from "./DataFilesSlice";
import { BinaryFileData, DataFilesData, DataFilesType, TextFileData } from "./DataFilesData";
import { DataFileModel, DataFilesModel, TextFileModel } from "./DataFilesModel";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException, loadingSlice, notificationSlice } from "@anthill/domino-ui-base";

export function* DataFilesSaga() {
  yield takeLatest(DataFilesSlice.actions.getDataFiles, getDataFiles);
  yield takeLatest(DataFilesSlice.actions.getDataFile, getDataFile);
  yield takeLatest(DataFilesSlice.actions.createDataFile, createDataFile);
  yield takeLatest(DataFilesSlice.actions.updateDataFile, updateDataFile);
  yield takeLatest(DataFilesSlice.actions.deleteDataFile, deleteDataFile);
  yield takeLatest(DataFilesSlice.actions.downloadFile, downloadFile);
}

export function* getDataFiles() {
  try {
    yield put(loadingSlice.actions.addLoadingState("getDataFiles"));
    const data: DataFilesData[] = yield call(DataFilesApi.getDataFiles);
    const model: DataFilesModel[] = [...data];
    yield put(DataFilesSlice.actions.getDataFilesCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(loadingSlice.actions.completedLoadingState("getDataFiles"));
  }
}

export interface GetDataFile {
  id: string;
  type: DataFilesType;
}
export function* getDataFile(action: PayloadAction<GetDataFile>) {
  try {
    let model: DataFileModel;
    if (action.payload.type === "TextFile") {
      const data: TextFileData = yield call(DataFilesApi.getTextFile, action.payload.id);
      model = data;
    } else {
      const data: TextFileData = yield call(DataFilesApi.getBinaryFile, action.payload.id);
      model = data;
    }
    yield put(DataFilesSlice.actions.getCurrentDataFileCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
    yield put(DataFilesSlice.actions.resetLoading());
  }
}

function* createDataFile(action: PayloadAction<DataFilesApi.CreateTextFile | DataFilesApi.CreateBinaryFile>) {
  try {
    let newDataFile: DataFilesModel;
    if ("data" in action.payload) {
      const data: DataFileModel = yield call(DataFilesApi.createTextFile, action.payload);
      newDataFile = {
        id: data.id,
        description: data.description,
        name: data.name,
        type: "TextFile",
      };
    } else {
      const data: DataFileModel = yield call(DataFilesApi.createBinaryFile, action.payload);
      newDataFile = {
        id: data.id,
        description: data.description,
        name: data.name,
        type: "BinaryFile",
      };
    }
    const currentDataFiles: DataFilesModel[] = yield select(selectDataFiles);
    const updatedDataFiles = [newDataFile, ...(currentDataFiles || [])];
    yield put(DataFilesSlice.actions.getDataFilesCompleted(updatedDataFiles));
    yield put(DataFilesSlice.actions.clearDataFile());
  } catch (e: unknown) {
    yield handleException(e);
    yield put(DataFilesSlice.actions.resetLoading());
  }
}

function* updateDataFile(action: PayloadAction<TextFileModel | DataFilesApi.UpdateBinaryFile>) {
  try {
    let updatedDataFile: DataFilesModel;
    if ("data" in action.payload) {
      const data: TextFileData = yield call(DataFilesApi.updateTextFile, action.payload);
      updatedDataFile = {
        id: data.id,
        name: data.name,
        description: data.description,
        type: "TextFile",
      };
    } else {
      const data: BinaryFileData = yield call(DataFilesApi.updateBinaryFile, action.payload);
      updatedDataFile = {
        id: data.id,
        name: data.name,
        description: data.description,
        type: "BinaryFile",
      };
    }

    const currentDataFiles: DataFilesModel[] = yield select(selectDataFiles);
    const updatedDataFiles = currentDataFiles?.map(dataFile => (dataFile.id === updatedDataFile.id ? updatedDataFile : dataFile));
    yield put(DataFilesSlice.actions.getDataFilesCompleted(updatedDataFiles));
    yield put(
      notificationSlice.actions.notify({
        message: "Value saved successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
    yield put(DataFilesSlice.actions.resetLoading());
  }
}

function* deleteDataFile(action: PayloadAction<string>) {
  try {
    yield call(DataFilesApi.deleteDataFile, action.payload);
    const currentDataFiles: DataFilesModel[] = yield select(selectDataFiles);
    const updatedDataFiles = currentDataFiles.filter(DataFile => DataFile.id !== action.payload);
    yield put(DataFilesSlice.actions.getDataFilesCompleted(updatedDataFiles));
  } catch (e: unknown) {
    yield handleException(e);
    yield put(DataFilesSlice.actions.resetLoading());
  }
}

export interface DownloadFile {
  dataFileId: string;
  fileName: string;
}
function* downloadFile(action: PayloadAction<DownloadFile>) {
  try {
    const file: Response = yield call(DataFilesApi.downloadFile, action.payload.dataFileId);
    const blob: Blob = yield file.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = action.payload.fileName;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    yield put(DataFilesSlice.actions.downloadFileCompleted());
  } catch (e: unknown) {
    yield handleException(e);
    yield put(DataFilesSlice.actions.resetLoading());
  }
}
