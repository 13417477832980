import { useState } from "react";
import { Box, Button, CloseButton, Flex, Icon, Input, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@anthill/domino-ui-base";
import { ReactComponent as FileIcon } from "../../assets/icons/fileIcon.svg";
import { chooseFile, handleDrag, onDropFile } from "./handlers";
import { FileNameSplitter } from "./components/FileNameSplitter/FileNameSplitter";
import s from "./DragAndDrop.module.scss";

interface Props {
  extensions: string;
  currentFile: File | null;
  changeCurrentFile: (file: File | null) => void;
  maxSize: number;
  importStatus?: "success" | "error";
  isPossibleReplace?: boolean;
  isPossibleClear?: boolean;
  isDownloadable?: boolean;
  handleDownload?: () => void;
}

export function DragAndDrop({
  extensions,
  currentFile,
  changeCurrentFile,
  maxSize,
  importStatus,
  isPossibleReplace = false,
  isPossibleClear = false,
  isDownloadable,
  handleDownload,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDragging, setIsDragging] = useState<boolean>(false);

  return (
    <Box
      className={s.fileUploadContainer}
      transition={"all .3s"}
      borderStyle={importStatus ? "solid !important" : "dashed !important"}
      borderColor={isDragging ? "mainPurple !important" : "line !important"}
      _hover={{ borderColor: "mainPurple !important" }}
      bgColor={"#F7FAFF"}
      onDragEnter={handleDrag({ setIsDragging, currentFile, isPossibleReplace })}
    >
      <Flex alignItems="center" gap="8px" direction="column">
        {!importStatus ? (
          <>
            {isDragging ? (
              <>
                <Icon color="#DCE7FB" as={FileIcon} boxSize="80px" />
              </>
            ) : (
              <Flex className={s.fileIn} direction="column" alignItems="center" gap="8px">
                <Icon
                  cursor={isDownloadable ? "pointer" : "default"}
                  color="black"
                  as={FileIcon}
                  boxSize="24px"
                  onClick={isDownloadable ? handleDownload : undefined}
                />
                {isPossibleClear && currentFile && (
                  <CloseButton
                    variant="popupCloseButton"
                    boxSize="16px"
                    className={s.closeButton}
                    onClick={() => changeCurrentFile(null)}
                  />
                )}
              </Flex>
            )}

            {currentFile ? (
              <Text color="black" variant="largeBold">
                <FileNameSplitter fileName={currentFile.name} />
              </Text>
            ) : (
              <>
                {isDragging ? (
                  <>
                    <Text color="darkGrey" variant="medium">
                      {t("Drag and drop the file here")}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text variant="largeBold">{t("Drag and drop the file here")}</Text>
                    <Text variant="medium" color="darkGrey">
                      {t("or press the button")}
                    </Text>
                    <Button htmlFor="file-upload" as="label" variant="dominoOutlineViolet" data-pw="select-file-button">
                      {t("Select file")}
                    </Button>
                    <Input
                      id="file-upload"
                      onChange={chooseFile({ dispatch, maxSize, changeCurrentFile, t, extensions })}
                      variant="unstyled"
                      type="file"
                      accept={extensions}
                    />
                  </>
                )}
              </>
            )}
            {isDragging && (
              <Box
                className={s.dragFileElement}
                onDragEnter={handleDrag({ setIsDragging, currentFile, isPossibleReplace })}
                onDragLeave={handleDrag({ setIsDragging, currentFile, isPossibleReplace })}
                onDragOver={handleDrag({ setIsDragging, currentFile, isPossibleReplace })}
                onDrop={onDropFile({ dispatch, extensions, maxSize, changeCurrentFile, setIsDragging, t })}
              ></Box>
            )}
          </>
        ) : (
          <Flex direction="column" gap="24px">
            {importStatus === "success" ? (
              <Flex direction="column" alignItems="center">
                <Text variant="large" color="darkGrey" mb="4px">
                  {t("Imported file")}:
                </Text>
                <Text color="black" variant="largeBold" data-pw="file-success">
                  <FileNameSplitter fileName={currentFile?.name ?? ""} />
                </Text>
              </Flex>
            ) : (
              <>
                <Flex direction="column" alignItems="center">
                  <Text variant="large" color="darkGrey" mb="4px" data-pw="file-failed">
                    {t("File failed")}:
                  </Text>
                  <Text color="black" variant="largeBold">
                    <FileNameSplitter fileName={currentFile?.name ?? ""} />
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
