import { Center, Spinner } from "@chakra-ui/react";
import s from "./Loader.module.scss";

export const Loader = () => {
  return (
    <Center className={s.spinnerContainer}>
      <Spinner className={s.spinner} />
    </Center>
  );
};
