import { baseApi } from "@anthill/domino-ui-base";
import { AgentModel } from "./AgentModel";

export const getAgents = () => {
  return baseApi.httpGet("/agents");
};

export type CreateAgentType = Pick<AgentModel, "name" | "description" | "prompt" | "dataFileIds">;
export const createAgent = (agent: CreateAgentType): AgentModel => {
  return baseApi.httpPost("/agents", agent) as AgentModel;
};

export const updateAgent = (agent: AgentModel): AgentModel => {
  return baseApi.httpPut(`/agents/${agent.id}`, agent) as AgentModel;
};

export const deleteAgent = (agentId: string): string => {
  return baseApi.httpDelete(`/agents/${agentId}`) as string;
};
