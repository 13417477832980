export const PATHS = {
  root: "/",
  agents: {
    root: "agents",
    newAgent: "new-agent",
  },
  dataFiles: {
    root: "data-files",
    newDataFile: "new-data-file",
  },
};
