import { Text } from "@chakra-ui/react";
import s from "./FileNameSplitter.module.scss";

interface Props {
  fileName: string;
  style?: string;
  variant?: string;
}

export const FileNameSplitter = ({ fileName, style, variant }: Props) => {
  const firstPart = fileName.length > 20 ? fileName.substring(0, 12) : "";
  const secondPart = fileName.length > 20 ? fileName.substring(fileName.length - 7, fileName.length) : "";

  return (
    <Text className={style ?? s.fileNameSplitterStyle} variant={variant ?? "largeBold"}>
      {fileName.length > 20 ? `${firstPart}...${secondPart}` : fileName}
    </Text>
  );
};
